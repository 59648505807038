<style lang="less">
  .autocomplete-complex-demos{
  }
  a{text-decoration:none};
</style>
<template>
			<Cell :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
  <div class="autocomplete-complex-demos frame-page h-panel">
    <div class="h-panel-bar"><span class="h-panel-title">开始修仙问道</span></div>
    <div class="h-panel-body">
		<Form :label-width="80" mode="twocolumn" ref="form" showErrorTip>
			<FormItem label="选择项目" style="width: 100%;">
                <Select style="float: left;" :datas="classlist" :filterable="true" keyName="cid" titleName="name" placeholder="请选择" @change="change">
					<template v-slot:item="{ item }">
						  <div>
							{{ item.name }} ({{item.price}}灵石)
						  </div>
					</template>
				</Select>
			 </FormItem>
			 <FormItem v-if="selectcid.content && selectcid.content!=''" label="" :single="true" prop="textarea">
			 	<div style="color:indianred;"><i class="h-icon-info" /> {{selectcid.content}}</div>
			 </FormItem>	
			 <FormItem v-if="selectcid.price && selectcid.price!=''" label="" :single="true" prop="textarea">
			 	<div style="color: red;"><i class="h-icon-completed" /> 预计: {{selectcid.price}} 灵石</div>
			 </FormItem>	
			 <FormItem v-if="selectcid.url && selectcid.url!=''" label="" :single="true" prop="textarea">
				<div style="color: green;"><i class="h-icon-link" /> <a :href="selectcid.url" target="_blank">{{selectcid.url}}</a> </div>
			 </FormItem>	
	
			<FormItem label="输入信息" :single="true" prop="textarea">
				<Textarea rows="10" v-model="userinfo" v-autosize placeholder="请输入学校、账号、密码(空格分开),点查询"></Textarea>
			</FormItem>		 
			<FormItem label="" :single="true" prop="textarea">
			    <Button icon="h-icon-search" color="primary" @click="get()">查询</Button>
				<Button color="green" @click="add()">下单</Button>
				<Button color="" @click="userinfo=''">清空</Button>
			</FormItem>	
		</Form>
    </div>
	
	 <Loading text="Loading" :loading="loading"></Loading>
    <hr>
	  <div class="frame-page h-panel" style="width: 400px;float: left;" v-for="(row,index) in chakerow" :key='index'>
			<div class="h-panel">
			  <div class="h-panel-bar">
				<span class="h-panel-title">{{row.userinfo}}</span>
				<span class="h-panel-right">
					<a v-if="row.code==1" style="color: green;">{{row.msg}}</a>
					<a v-else style="color: red;">{{row.msg}}</a>
				</span>
			  </div>
			  <div class="h-panel-body">
				  <div v-for="(res,index2) in row.data" :key="index2">
					  <Checkbox v-model="check" :value='res'>{{res.name}}</Checkbox>
				  </div>
				 
			  </div>
			</div>
	  </div>
	
  </div>
  


  
  
  		</Cell>
</template>
<script>
import FormModel from '@model/Form';
import Request from '@common/request';
import { message,notice } from 'heyui';
export default {
  data() {
    return {
		classlist:[],
		selectcid:{},
		userinfo:'',
		chakerow:[],
		shichang:200,
		fenshu:100,
		loading:false,
		props:{
			children:'data',
			label:'name',
			disabled:'disabled'
		},
		check:[],
    };
  },
  methods: {
	get_class: async function () {
	  let res = await Request.Order.get_class();
	  if(res.code==1){
		  this.classlist=res.data;
		  // console.log(this.classlist)
	  }
	},
	change(info){
		// console.log(info)
		this.selectcid=info
		this.noticeTitle('warn',info.name,info.content)
	},
	noticeTitle(type,title,content) {
	  let text = { info: '消息', success: '成功', warn: '警告', error: '错误' }[type];
	  notice({
		type,
		title: title,
		content: content
	  });
	},
	get(){
		if(!this.selectcid.cid || this.userinfo==''){
			message.error("所有项目不能为空")
			return false;
		}
		//console.log(this.selectcid)
		this.chakerow=[]
		this.loading = true;
		var userinfo=this.userinfo.replace(/\r\n/g, "[br]").replace(/\n/g, "[br]").replace(/\r/g, "[br]");      	           	    
		userinfo=userinfo.split('[br]');//分割
		for(var i=0;i<userinfo.length;i++){
			 var info=userinfo[i].replace(/\s+/g,' ')
			 console.log(info)
			 Request.Order.get({cid:this.selectcid.cid,userinfo:info}).then(res=>{
				// console.log(res);
				this.loading = false;
				this.chakerow.push(res)
			})
		}
	},
	add(){
			
		if(!this.selectcid.cid || this.userinfo==''){
			message.error("所有项目不能为空")
			return false;
		}
		// console.log(this.selectcid.cid);
		
		if(this.check.length<1){
			message.error("请先选择课程")
			return false;
		}
		this.loading=true;

		Request.Order.add({cid:this.selectcid.cid,data:this.check,shichang:this.shichang,fenshu:this.fenshu}).then(res=>{
			if(res.code==1){
			   this.chakerow=[]
			   this.userinfo=[]
			   this.check=[];
			   message.success(res.msg)
			   this.loading=false;
			}else{
			   message.error(res.msg)
			   this.loading=false;
			}
		});
		

	},
	
  },
  mounted() {
  	this.get_class()
  }
};
</script>
